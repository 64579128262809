import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import algoliasearch from 'algoliasearch/lite';
import { EventService } from 'src/app/services/event.service';

const searchClient = algoliasearch(
  environment.algoliasearch.appId,
  environment.algoliasearch.apiKey
);

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  config = {
    indexName: 'events',
    hitsPerPage: 2,
    searchClient,
    filters: 'visible:true',
  };
  recommendations;

  constructor(    
    private _location: Location,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getRecommendations();
  }

  getRecommendations(): void {
    this.eventService.getRecommendations().subscribe(results => {
      this.recommendations = results;
    });
  }

  backLink() {
    this._location.back();
  }

}