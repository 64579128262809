import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormGroupDirective, NgForm, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { Location } from '@angular/common';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

export class SignupErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  signupForm : UntypedFormGroup;
  hide: boolean;
  errorMessage;
  returnUrl: string;
  couponcode: string;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,  
    private _location: Location,  
    private authService: AuthService,
    private analytics: AngularFireAnalytics
    ) { }

  
  matcher = new SignupErrorStateMatcher();

  ngOnInit() {

    this.route.queryParams
      .subscribe(params => {
        if(params.subscriptionPlanId) {
          localStorage.setItem('selectedSubscriptionPlanId', params.subscriptionPlanId);
        }
        if(params.couponcode) {
          localStorage.setItem('couponcode', params.couponcode);
          this.couponcode = params.couponcode;
        }
      }
    );

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.signupForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern(/^([\w-]+(?:\.[\w+-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i)]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  backLink() {
    this._location.back();
  }

  validateForm(): boolean {
    this.errorMessage = "";
    if (this.signupForm.valid) return true;
    if (this.signupForm.hasError('required','firstName') || this.signupForm.hasError('required','lastName') || this.signupForm.hasError('required','email') || this.signupForm.hasError('required','password')) {
      this.errorMessage = "Bitte prüfe alle markierten Eingabefelder.";
      return false;
    } else if (this.signupForm.hasError('email','email') ){
      this.errorMessage = "Bitte gebe eine gültige E-Mail ein.";
      return false;
    }
    return false;
  }


  async facebookSignup(){
    this.analytics.logEvent('signup_facebook');
    await this.authService.facebookSignin()
    .then(res => {
      if (res) {
        this.router.navigate(['/subscription/personal-data']);
      }
    })
  }

  async googleSignup(){
    this.analytics.logEvent('signup_google');
    await this.authService.googleSignin()
    .then(res => {
      if (res) {
        this.router.navigate(['/subscription/personal-data']);
      }
    })
  }

  emailSignup(value) {
    this.analytics.logEvent('signup_email');
    var selectedSubscriptionPlanId;
    if (localStorage.getItem('selectedSubscriptionPlanId')) {
      selectedSubscriptionPlanId = localStorage.getItem('selectedSubscriptionPlanId');
    } else {
      selectedSubscriptionPlanId = "single";
    }
    if (!this.validateForm()) return null;
    this.authService.emailSignup(value,selectedSubscriptionPlanId)
    .then(res => {
      this.errorMessage = "";
      this.router.navigate(['/subscription/signup/email']);
    }, err => {
      if (err.code == "auth/invalid-email") { 
        this.errorMessage = "Bitte gebe eine gültige E-Mail ein.";
      }
      if (err.code == "auth/email-already-in-use") { 
        this.router.navigate(["/login"]);
      }
      if (err.code == "auth/weak-password") { 
        this.errorMessage = "Dein Passwort muss mindestens 6 Zeichen lang sein.";
      }
      
    });
  }

}
