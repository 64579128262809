import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainpageLayoutComponent } from './layouts/mainpage-layout/mainpage-layout.component';
import { StartComponent } from './views/start/start.component';
import { MovieComponent } from './views/categories/movie/movie.component';
import { MusicComponent } from './views/categories/music/music.component';
import { ExhibitionComponent } from './views/categories/exhibition/exhibition.component';
import { TheaterComponent } from './views/categories/theater/theater.component';
import { SportComponent } from './views/categories/sport/sport.component';
import { SubpageLayoutComponent } from './layouts/subpage-layout/subpage-layout.component';
import { EventComponent } from './views/detail/event/event.component';
import { ModalLayoutComponent } from './layouts/modal-layout/modal-layout.component';
import { SearchComponent } from './views/search/search.component';
import { LocationComponent } from './views/detail/location/location.component';
import { GenreComponent } from './views/detail/genre/genre.component';
import { PersonComponent } from './views/detail/person/person.component';
import { OutdoorComponent } from './views/categories/outdoor/outdoor.component';
import { SignInComponent } from './views/sign-in/sign-in.component';
import { SignUpComponent } from './views/checkout/sign-up/sign-up.component';
import { IntroComponent } from './views/intro/intro.component';
import { AccountComponent } from './views/account/account.component';
import { AuthGuard } from './guard/auth.guard';
import { IntroMusicComponent } from './views/intro/intro-music/intro-music.component';
import { IntroMovieComponent } from './views/intro/intro-movie/intro-movie.component';
import { IntroCultureComponent } from './views/intro/intro-culture/intro-culture.component';
import { IntroCompleteComponent } from './views/intro/intro-complete/intro-complete.component';
import { IntroMusicResultsComponent } from './views/intro/intro-music-results/intro-music-results.component';
import { DetailpageLayoutComponent } from './layouts/detailpage-layout/detailpage-layout.component';
import { ProductOverviewComponent } from './views/checkout/product-overview/product-overview.component';
import { PaymentComponent } from './views/checkout/payment/payment.component';
import { EmailCheckComponent } from './views/checkout/email-check/email-check.component';
import { PersonalDataComponent } from './views/checkout/personal-data/personal-data.component';
import { ConfirmationComponent } from './views/checkout/confirmation/confirmation.component';
import { BookingMovieSeatComponent } from './views/booking/booking-movie-seat/booking-movie-seat.component';
import { BookingLayoutComponent } from './layouts/booking-layout/booking-layout.component';
import { ModalLayoutNewComponent } from './layouts/modal-layout-new/modal-layout-new.component';
import { BookingConfirmationComponent } from './views/booking/booking-confirmation/booking-confirmation.component';
import { BookingSummaryComponent } from './views/booking/booking-summary/booking-summary.component';
import { UpgradeComponent } from './views/checkout/upgrade/upgrade.component';
import { ChangeComponent } from './views/checkout/change/change.component';
import { CancelComponent } from './views/checkout/cancel/cancel.component';
import { TicketComponent } from './components/booking/ticket/ticket.component';
import { BookingDetailComponent } from './views/booking/booking-detail/booking-detail.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { PasswordComponent } from './views/password/password.component';
import { TodayComponent } from './views/categories/today/today.component';
import { PauseComponent } from './views/checkout/pause/pause.component';
import { BookingComponent } from './views/booking/booking/booking.component';
import { OthersComponent } from './views/categories/others/others.component';
import { ReactivateComponent } from './views/checkout/reactivate/reactivate.component';
import { WalkthroughComponent } from './views/checkout/walkthrough/walkthrough.component';
import { AddPaymentMethodComponent } from './views/checkout/add-payment-method/add-payment-method.component';
import { SignOutComponent } from './views/sign-out/sign-out.component';
import { BookingLogoutPreviewComponent } from './views/booking/booking-logout-preview/booking-logout-preview.component';

const routes: Routes = [
  {
    path: '', 
    component: MainpageLayoutComponent,
    children: [  
      { path: '', redirectTo: '/start', pathMatch: 'full' },
      { path: 'start', component: StartComponent, title: 'My Event Pass · Dein Zugang zu den besten Events'},
      { path: 'movie', component: MovieComponent, title: 'Die besten Filme mit My Event Pass'},
      { path: 'music', component: MusicComponent, title: 'Dein Konzert-Erlebnis mit My Event Pass'},
      { path: 'exhibition', component: ExhibitionComponent, title: 'Entdecke Ausstellungen und Museen mit My Event Pass'},
      { path: 'theater', component: TheaterComponent, title: 'Erlebe die besten Theateraufführungen mit My Event Pass'},
      { path: 'sport', component: SportComponent},
      { path: 'outdoor', component: OutdoorComponent}
    ]
  },
  {
    path: '', 
    component: SubpageLayoutComponent,
    children: [  
      { path: 'movie/:id', component: EventComponent},
      { path: 'music/:id', component: EventComponent},
      { path: 'theater/:id', component: EventComponent},
      { path: 'exhibition/:id', component: EventComponent},
      { path: 'sport/:id', component: EventComponent},
      { path: 'theater/:id', component: EventComponent},
      { path: 'exhibition/:id', component: EventComponent},
      { path: 'sport/:id', component: EventComponent}
    ]
  },
  {
    path: '', 
    component: DetailpageLayoutComponent,
    children: [  
      // { path: 'movie/person/:id', component: PersonComponent},
      { path: 'location/:id', component: LocationComponent},
      { path: 'subscription', component: ProductOverviewComponent},
      { path: 'subscription/walkthrough', component: WalkthroughComponent},
      { path: 'weiteres', component: OthersComponent},
      { path: ':category/genre/:id', component: GenreComponent},
      { path: ':category/person/:id', component: PersonComponent},      
      { path: 'person/:id', component: PersonComponent},      
      { path: 'account', component: AccountComponent, canActivate: [AuthGuard]},
      { path: 'theater/genre/:id', component: GenreComponent},
      { path: ':city/heute', component: TodayComponent, title: 'Was kann man Heute in München erleben? Erfahre es mit My Event Pass'},
      { path: ':city/morgen', component: TodayComponent, title: 'Was kann man Morgen in München erleben? Erfahre es mit My Event Pass'},
      { path: ':city/wochenende', component: TodayComponent, title: 'Was kann man am Wochenende in München erleben? Erfahre es mit My Event Pass'}

    ]
  },
  {
    path: '', 
    component: ModalLayoutComponent,
    children: [  
      { path: 'intro', component: IntroComponent },
      { path: 'intro/music', component: IntroMusicComponent },
      { path: 'intro/music/results', component: IntroMusicResultsComponent },
      { path: 'intro/movie', component: IntroMovieComponent },
      { path: 'intro/culture', component: IntroCultureComponent },
      { path: 'intro/complete', component: IntroCompleteComponent }
    ]
  },
  {
    path: '', 
    component: ModalLayoutNewComponent,
    children: [    
      { path: 'search', component: SearchComponent},    
      { path: 'login', component: SignInComponent},
      { path: 'logout', component: SignOutComponent},
      { path: 'password', component: PasswordComponent},
      { path: 'subscription/signup', component: SignUpComponent},
      { path: 'subscription/signup/email', component: EmailCheckComponent},
      { path: 'subscription/personal-data', component: PersonalDataComponent, canActivate: [AuthGuard]},
      { path: 'subscription/payment', component: PaymentComponent, canActivate: [AuthGuard] },
      { path: 'subscription/finish', component: ConfirmationComponent, canActivate: [AuthGuard] },
      { path: 'account/upgrade', component: UpgradeComponent, canActivate: [AuthGuard]},
      { path: 'account/change', component: ChangeComponent, canActivate: [AuthGuard]},
      { path: 'account/cancel', component: CancelComponent, canActivate: [AuthGuard]},
      { path: 'account/reactivate', component: ReactivateComponent, canActivate: [AuthGuard]},
      { path: 'account/paymentinfo', component: AddPaymentMethodComponent, canActivate: [AuthGuard]},
      { path: 'account/pause', component: PauseComponent, canActivate: [AuthGuard]}
    ]
  },
  {
    path: '', 
    component: BookingLayoutComponent,
    children: [  
      // { path: ':category/:id/booking/:showtimeid', component: BookingMovieSeatComponent, canActivate: [AuthGuard]},    
      { path: ':category/:id/booking/:showtimeid/preview', component: BookingLogoutPreviewComponent},    
      { path: ':category/:id/booking/:showtimeid', component: BookingComponent},    
      { path: ':category/:id/booking/:showtimeid/seat/checkout/:session/:signature', component: BookingSummaryComponent, canActivate: [AuthGuard]},    
      { path: 'booking/confirmation', component: BookingConfirmationComponent, canActivate: [AuthGuard]},
      { path: 'account/booking/:id', component: BookingDetailComponent, canActivate: [AuthGuard]}
    ]
  },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
